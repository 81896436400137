import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { DefaultDataTableHeader } from './components/DefaultDataTableHeader';
import { DefaultLoadingOverlay } from './components/DefaultLoadingOverlay';
import { DefaultPaginatedTableFooter } from './components/DefaultPaginatedTableFooter';
import { DefaultTableEmptyState } from './components/TableEmptyState';
import { ThemedDataGrid } from './components/ThemedDataGrid';
import { useDefaultColumns } from './hooks/internal/useDefaultColumns';
import { useDefaultProps } from './hooks/internal/useDefaultProps';
import { usePageSize } from './hooks/internal/usePageSize';
import { DataTableProps } from './types';

export function DataTable(externalProps: DataTableProps) {
  const props = useDefaultProps(externalProps);
  const {
    header,
    slots: externalSlots,
    initialState: externalInitialState,
    ...dataGridProps
  } = props;

  const columns = useDefaultColumns(props);
  const pageSize = usePageSize(props);

  const pageSizeOptions = useMemo(() => {
    return [pageSize];
  }, [pageSize]);

  const slots = useMemo(() => {
    return {
      loadingOverlay: DefaultLoadingOverlay,
      noRowsOverlay: DefaultTableEmptyState,
      footer: props.hideFooterPagination ? Box : DefaultPaginatedTableFooter,
      ...externalSlots,
    };
  }, [externalSlots, props.hideFooterPagination]);

  const initialState = useMemo(() => {
    return {
      pagination: { paginationModel: { pageSize } },
      ...externalInitialState,
    };
  }, [externalInitialState, pageSize]);

  return (
    <Box data-testid="DataTable">
      {/* Ideally this is a Toolbar slot (left, center, right, etc.), but someone else can add that later if they need to */}
      {header && <DefaultDataTableHeader>{header}</DefaultDataTableHeader>}
      <Box data-testid="DataTable-tableContainer">
        <ThemedDataGrid
          {...dataGridProps}
          slots={slots}
          columns={columns}
          pageSizeOptions={pageSizeOptions}
          initialState={initialState}
        />
      </Box>
    </Box>
  );
}
