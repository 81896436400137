import { useTheme } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { ComponentType, ForwardedRef, forwardRef } from 'react';

import { IconProps } from '@/components/icons/ThumbsUpIcon';
import { Loader } from '@/components/progress/Loader';

import { ButtonRoot } from './ButtonRoot';
import { getCSSPropertiesByVariant } from './styles';
import { ButtonSize, ButtonVariant } from './types';

type IconButtonSize = Exclude<ButtonSize, '_bg'>;

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ComponentType<IconProps>;
  ariaLabel: string;
  iconProps?: Partial<IconProps>;
  variant?: ButtonVariant;
  size: IconButtonSize;
  disabled?: boolean;
  loading?: boolean;
  sx?: SxProps<Theme>;
}

const iconSizesByButtonSize: Record<IconButtonSize, number> = {
  xs: 16,
  sm: 20,
  md: 24,
  lg: 32,
};

// This is a generic icon button for use with thumbs up and thumbs down icons or a
// similar pair of icons that represent a positive and negative action.
function IconButtonInner(
  {
    iconProps,
    icon: Icon,
    ariaLabel,
    loading,
    variant = 'secondary',
    ...buttonProps
  }: IconButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { size } = buttonProps;
  const theme = useTheme();
  const iconSize = iconSizesByButtonSize[size];
  const computedDisabled = buttonProps.disabled || !!loading;
  const variantStyle = getCSSPropertiesByVariant(
    variant,
    computedDisabled,
    theme
  );
  return (
    <ButtonRoot
      ref={ref}
      aria-label={ariaLabel}
      square
      variant={variant}
      {...buttonProps}
    >
      <Icon
        size={iconSize}
        {...iconProps}
        sx={{
          ...iconProps?.sx,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          visibility: loading ? 'hidden' : 'inherit',
        }}
      />
      {loading && (
        <Loader
          boxProps={{
            sx: {
              display: 'flex',
              position: 'absolute',
            },
          }}
          circularProgressProps={{
            size: iconSize,
            sx: {
              color: variantStyle.color,
            },
          }}
        />
      )}
    </ButtonRoot>
  );
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  IconButtonInner
);
