import { styled } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseCheckboxInputProps,
  FormControlCheckboxInputProps,
  HelpTextVariant,
} from '../inputTypes';

interface CheckboxInputProps extends FormControlCheckboxInputProps {
  id?: string;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  label?: React.ReactNode;
  contextualHelp?: JSX.Element;
}

export const StyledCheckbox = styled((props: CheckboxInputProps) => {
  const { error: _error, id, ...rest } = props;

  return (
    <MuiCheckbox
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={props.value ?? false}
      inputProps={
        {
          'data-testid': `checkbox-${props.name ?? ''}`,
          id,
        } as React.InputHTMLAttributes<HTMLInputElement>
      }
      {...rest}
    />
  );
})(() => ({
  color: COLORS.GRAY[300],
  // the native input has a lot of padding around it because of the treatment of the MUI
  // "ripple" interaction. we disable that interaction, so removing the padding to allow
  // consumers of this more flexibility around styling
  padding: '0 !important',
  zIndex: 2,
  // this places a white background behind the checkbox. we have to do it this janky way
  // because the inherent spacing in the checkbox is created by an svg with a specific path,
  // and just putting a backgroundColor on the svg causes the background to spill outside
  // of just the checkbox location itself
  '&.MuiCheckbox-root:after': {
    content: '""',
    left: 3,
    height: 18,
    width: 18,
    borderRadius: 2,
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: -1,
  },
  '&.MuiSvgIcon-root': {
    zIndex: 1,
  },
  '&.Mui-checked': {
    color: COLORS.NAVY[500],
  },
  '&.Mui-unchecked': {
    color: COLORS.GRAY[300],
  },
  '&.Mui-disabled': {
    color: COLORS.GRAY[200],
  },
  '&.Mui-focusVisible': {
    outline: 'solid black 2px',
    // the border radius is also set to be very high from MUI (to make the focus ring circular)
    // so we normalize that here
    borderRadius: '8px',
  },
}));
export function Checkbox({
  errorMessage,
  helpText,
  helpTextVariant,
  contextualHelp,
  label,
  ...inputProps
}: CheckboxInputProps) {
  return (
    <FormControl<BaseCheckboxInputProps>
      component={StyledCheckbox}
      inputProps={inputProps}
      labelPosition="right"
      errorMessage={errorMessage}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      contextualHelp={contextualHelp}
      id={inputProps.id}
      label={label}
    />
  );
}
