// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type PersonalAccountDetailsCard_OwnerPrincipalFragment = { __typename?: 'Principal', id: string, individual?: { __typename?: 'ClientProfile', id: string, legalName: string } | null };

export type PersonalAccountDetailsCard_ControllingPartyFragment = { __typename?: 'ControllingParty', id: string, individual?: { __typename?: 'ClientProfile', id: string, legalName: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export const PersonalAccountDetailsCard_OwnerPrincipalFragmentDoc = gql`
    fragment PersonalAccountDetailsCard_OwnerPrincipal on Principal {
  id
  individual {
    id
    legalName
  }
}
    `;
export const PersonalAccountDetailsCard_ControllingPartyFragmentDoc = gql`
    fragment PersonalAccountDetailsCard_ControllingParty on ControllingParty {
  id
  individual {
    id
    legalName
  }
  organization {
    id
    name
  }
}
    `;