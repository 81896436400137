import { useLocalStorage } from 'react-use';

/** Handle boolean localStorage values for the `useLocalStorage` hook */
export const LOCAL_STORAGE_BOOLEAN_SERIALIZER: Extract<
  Parameters<typeof useLocalStorage<boolean>>[2],
  { raw: false }
> = {
  raw: false,
  serializer: (value: boolean) => (value ? 'true' : 'false'),
  deserializer: (value: unknown) => value === 'true',
};
