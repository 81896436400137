import * as Sentry from '@sentry/nextjs';

import {
  getAppVersion,
  getEnvironment,
  isDevelopmentMode,
} from './src/utils/environmentUtils';

const blockedErrorMessages = [
  // this error shows up frequently and has no user-facing impact
  'ResizeObserver loop completed with undelivered notifications',
  // generated during tests and from internal dev tools, no user-facicng impact
  'BootstrapWaterfall',
  // happens when a user navigates away from a page with the onboarding sidebar open while polling;
  // there's no graceful way to abort pending call, and the hacky way breaks polling, so the error
  // is stifled for users and can be safely ignored
  'HouseholdOnboardingSidebar',
  'HouseholdOnboardingSidebarSubscription',
];

/**
 * @description Returns true if the event should be blocked from being sent to Sentry
 */
function matchesBlockedEvent(event: Sentry.ErrorEvent): boolean {
  // to be extra safe, only block events with a single exception
  if (event.exception?.values?.length !== 1) return false;

  const exception = event.exception.values[0];
  const hasBlockedMessage = blockedErrorMessages.some((blockedMessage) =>
    (exception?.value ?? '').includes(blockedMessage)
  );

  if (hasBlockedMessage) {
    return true;
  }

  return false;
}

function beforeSentrySend(event: Sentry.ErrorEvent): Sentry.ErrorEvent | null {
  if (matchesBlockedEvent(event)) return null;
  if (isDevelopmentMode()) return null;

  return event;
}

// Shared Sentry config
export const sentryConfig:
  | Sentry.BrowserOptions
  | Sentry.NodeOptions
  | Sentry.EdgeOptions = {
  dsn: 'https://b072d3c5888147c8b64b239c7a6cfc5a@o4503902548262912.ingest.sentry.io/4503959315283968',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  beforeSend: beforeSentrySend,

  environment: getEnvironment(),
  release: getAppVersion(),
};
