import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { sortBy } from 'lodash';
import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { GiftingProposalGiftRecipientKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { GiftProposalProjection } from '../../giftProposal.types';
import { AnnuallyRecurringValue as GiftAnnuallyRecurringValue } from './components/ScenarioGiftModal/ScenarioGiftModal.fields';
import { AnnuallyRecurringValue as IncomeAndExpensesAnnuallyRecurringValue } from './components/ScenarioIncomeAndExpensesModal/ScenarioIncomeAndExpensesModal.fields';
import { useGiftDesignerModelScenariosContext } from './contexts/GiftDesignerModelScenarios.context';
import { DEFAULT_BASIC_INFORMATION_FORM_VALUES } from './GiftDesignerBasicInformationForm.utils';
import {
  GiftDesignerModelScenariosFormShape,
  GiftDesignerScenarioFormShape,
  ScenarioGiftFormShapeAnnuallyRecurring,
  ScenarioGiftFormShapeOneTime,
  ScenarioIncomeAndExpensesFormShapeAnnuallyRecurring,
  ScenarioIncomeAndExpensesFormShapeOneTime,
} from './GiftDesignerModelScenariosForm.types';
import {
  GetGiftDesignerModelScenariosFormData_GiftingProposalCashFlowFragment,
  GetGiftDesignerModelScenariosFormData_GiftingScenariosFragment,
  GetGiftDesignerModelScenariosFormData_ProposalFragment,
  useGetGiftDesignerModelScenariosFormDataLazyQuery,
} from './graphql/GetGiftDesignerModelScenariosFormData.generated';

interface UseGiftDesignerModelScenariosDefaultValuesProps {
  proposalId: string;
}

export const NO_GIFTING_SENTINEL = '__no_gifting__' as const;

export const DEFAULT_GROWTH_PERCENTAGE = new Decimal(3);

export function validateYearRange(
  value: unknown,
  lengthOfAnalysis: Decimal,
  opts?: {
    gt?: Decimal | null;
    gtEq?: Decimal | null;
    lt?: Decimal | null;
    ltEq?: Decimal | null;
    errorMessage?: string;
  }
): string | undefined {
  if (!value) {
    return undefined;
  }

  let decimalValue;

  if (!Decimal.isDecimal(value)) {
    // If not a decimal, try to convert to a number
    const valueAsNumber = Number(value);
    if (isNaN(valueAsNumber)) {
      return 'Year must be a number';
    }
    decimalValue = new Decimal(valueAsNumber);
  } else {
    decimalValue = value;
  }

  const minYear = new Decimal(getYear(new Date()));
  const maxYear = new Decimal(lengthOfAnalysis.plus(getYear(new Date())));

  if (decimalValue.lt(minYear)) {
    return opts?.errorMessage ?? `Minimum year is ${minYear.toString()}`;
  }

  if (decimalValue.gt(maxYear)) {
    return opts?.errorMessage ?? `Maximum year is ${maxYear.toString()}`;
  }

  if (opts?.gt && decimalValue.lte(opts.gt)) {
    return 'Year range is invalid';
  }

  if (opts?.gtEq && decimalValue.lt(opts.gtEq)) {
    return 'Year range is invalid';
  }

  if (opts?.lt && decimalValue.gte(opts.lt)) {
    return 'Year range is invalid';
  }

  if (opts?.ltEq && decimalValue.gt(opts.ltEq)) {
    return 'Year range is invalid';
  }

  return undefined;
}

function getScenarioGiftsFromScenario(
  scenario: GetGiftDesignerModelScenariosFormData_GiftingScenariosFragment,
  lengthOfAnalysis: Decimal
) {
  const unorderedScenarioGifts = scenario.scenarioGifts?.map((gift) => {
    const recipientInformation = (() => {
      switch (gift.recipientKind) {
        case GiftingProposalGiftRecipientKind.OutOfEstatePortfolio:
          return {
            recipientId: gift.recipientPortfolio?.id ?? '',
            recipientKind:
              GiftingProposalGiftRecipientKind.OutOfEstatePortfolio,
          };
        case GiftingProposalGiftRecipientKind.Individual:
          return {
            recipientId: gift.recipientClientProfile?.id ?? '',
            recipientKind: GiftingProposalGiftRecipientKind.Individual,
          };
        case GiftingProposalGiftRecipientKind.Organization:
          return {
            recipientId: gift.recipientClientOrganization?.id ?? '',
            recipientKind: GiftingProposalGiftRecipientKind.Organization,
          };
        default:
          return {
            recipientId: '',
            recipientKind: null,
          };
      }
    })();

    const commonGiftProperties = {
      _id: '', // This will be replaced by a field array id when the field array is initialized
      displayName: gift.displayName,
      ...recipientInformation,
      amount: gift.amount,
      discount: gift.discount ?? false,
      discountPercent: gift.discountPercentage ?? null,
      startYear: new Decimal(gift.startYear),
      isTaxable: gift.isTaxable,
      nonTaxableGiftType: gift.nonTaxableGiftType ?? null,
      senderIds: gift.senders?.map((s) => s.grantor.id) ?? [],
      order: gift.order,
      lengthOfAnalysis,
      isNewRecipient: false,
    };

    if (gift.annuallyRecurring) {
      return {
        ...commonGiftProperties,
        annuallyRecurring: GiftAnnuallyRecurringValue.true,
        endYear: new Decimal(
          gift.endYear ?? lengthOfAnalysis.plus(getYear(new Date()))
        ),
        growthPercentage: gift.growthPercentage ?? DEFAULT_GROWTH_PERCENTAGE,
      } as const satisfies ScenarioGiftFormShapeAnnuallyRecurring;
    } else {
      return {
        ...commonGiftProperties,
        annuallyRecurring: GiftAnnuallyRecurringValue.false,
        endYear: null,
        growthPercentage: null,
      } as const satisfies ScenarioGiftFormShapeOneTime;
    }
  });

  return sortBy(unorderedScenarioGifts, ['order']);
}

export function giftingProposalCashFlowFragmentToScenarioCashFlow(
  cf: GetGiftDesignerModelScenariosFormData_GiftingProposalCashFlowFragment,
  isBaselineCashFlow: boolean,
  lengthOfAnalysis: Decimal
) {
  const commonGiftProperties = {
    _id: '', // This will be replaced by a field array id when the field array is initialized
    displayName: cf.displayName,
    amount: cf.amount,
    startYear: new Decimal(cf.startYear),
    order: cf.order,
    cashFlowType: cf.cashFlowType,
    isBaselineCashFlow,
  };

  if (cf.annuallyRecurring) {
    return {
      ...commonGiftProperties,
      annuallyRecurring: IncomeAndExpensesAnnuallyRecurringValue.true,
      endYear: new Decimal(
        cf.endYear ?? lengthOfAnalysis.plus(getYear(new Date()))
      ),
      growthPercentage: cf.growthPercentage ?? DEFAULT_GROWTH_PERCENTAGE,
      lengthOfAnalysis,
    } as const satisfies ScenarioIncomeAndExpensesFormShapeAnnuallyRecurring;
  } else {
    return {
      ...commonGiftProperties,
      annuallyRecurring: IncomeAndExpensesAnnuallyRecurringValue.false,
      endYear: null,
      growthPercentage: null,
      lengthOfAnalysis,
    } as const satisfies ScenarioIncomeAndExpensesFormShapeOneTime;
  }
}

function getScenarioCashFlowsFromScenario(
  scenario: GetGiftDesignerModelScenariosFormData_GiftingScenariosFragment,
  baselineCashFlows: GetGiftDesignerModelScenariosFormData_GiftingProposalCashFlowFragment[],
  lengthOfAnalysis: Decimal
) {
  const baselineCashFlowIds = new Set(baselineCashFlows.map((cf) => cf.id));
  const scenarioCashFlows: GetGiftDesignerModelScenariosFormData_GiftingProposalCashFlowFragment[] =
    scenario.scenarioCashFlows ?? [];

  const unorderedScenarioCashFlows = [
    ...baselineCashFlows,
    ...scenarioCashFlows,
  ].map((cf) => {
    const isBaselineCashFlow = baselineCashFlowIds.has(cf.id);

    return giftingProposalCashFlowFragmentToScenarioCashFlow(
      cf,
      isBaselineCashFlow,
      lengthOfAnalysis
    );
  });

  return sortBy(unorderedScenarioCashFlows, ['order']);
}

function getGiftScenariosFromQueryData(
  proposal: GetGiftDesignerModelScenariosFormData_ProposalFragment['giftingProposal'],
  lengthOfAnalysis: Decimal
) {
  const sorted =
    [...(proposal?.giftingScenarios ?? [])].sort((a, b) => a.order - b.order) ??
    [];

  const baselineCashFlows: GetGiftDesignerModelScenariosFormData_GiftingProposalCashFlowFragment[] =
    proposal?.baseCashFlows ?? [];

  return sorted.map((scenario) => {
    // Kinda a hack, but we either have a the default scenario created by the backend
    // when a proposal is initialized, or we have a special one indicated by the NO_GIFTING
    // sentinel value in the display name. This allows us to clear the proposal's scenarios
    // without having to keep track of the default scenario id.
    let isNoGiftingScenario = scenario.isBaseline;

    if (!isNoGiftingScenario) {
      isNoGiftingScenario =
        // this same check is also done on the client cache, but appears to be unreliable
        scenario.displayName === NO_GIFTING_SENTINEL || scenario.isDefault;
    }
    return {
      _id: '', // This will be replaced by a field array id when the field array is initialized
      giftScenarioId: scenario.id,
      name: scenario.displayName,
      isNoGiftingScenario,
      scenarioGifts: getScenarioGiftsFromScenario(scenario, lengthOfAnalysis),
      scenarioCashFlows: getScenarioCashFlowsFromScenario(
        scenario,
        baselineCashFlows,
        lengthOfAnalysis
      ),
    } as const satisfies GiftDesignerScenarioFormShape;
  });
}

export function useGiftDesignerModelScenariosDefaultValues({
  proposalId,
}: UseGiftDesignerModelScenariosDefaultValuesProps) {
  const { createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { setProposal } = useGiftDesignerModelScenariosContext();

  const [queryFormData, queryProps] =
    useGetGiftDesignerModelScenariosFormDataLazyQuery({
      fetchPolicy: 'cache-and-network', // Cache can be stale if loaded the form previously
      variables: {
        where: {
          id: proposalId,
        },
      },
      onError: createErrorFeedback(
        'There was an error loading the gift proposal details.'
      ),
    });

  const getDefaultValues = useCallback<
    () => Promise<GiftDesignerModelScenariosFormShape>
  >(async () => {
    const { data } = await queryFormData();
    const proposals = getNodes(data?.proposals);

    if (!(proposals.length === 1)) {
      const errorDescription =
        'Expected exactly one gift proposal to be returned.';
      const err = new Error(errorDescription);

      reportError(errorDescription, err, {
        proposalId,
      });
      throw err;
    }

    const proposal = proposals[0]?.giftingProposal ?? null;

    // Update designer context with the fetched proposal
    setProposal(proposal);

    const lengthOfAnalysis = new Decimal(
      proposal?.lengthOfAnalysis ??
        DEFAULT_BASIC_INFORMATION_FORM_VALUES.lengthOfAnalysis
    );

    return {
      scenarios: getGiftScenariosFromQueryData(proposal, lengthOfAnalysis),
      yearOfAnalysis: proposal?.selectedYearOfAnalysis ?? getYear(new Date()),
      annualPreTaxReturn: proposal?.selectedPreTaxReturnCategory || null,
      showAfterEstateTax: proposal?.showAfterEstateTax ?? false,
      exemptionSunsets: proposal?.exemptionSunsets ?? false,
      exemptionGrowthRate: proposal?.exemptionGrowthRate ?? new Decimal(2),
      lengthOfAnalysis,
      taxReturnLimits: {
        lowPreTaxReturn: proposal?.preTaxReturnPercentageLow || null,
        lowTaxDrag: proposal?.taxDragPercentageLow || null,
        mediumPreTaxReturn: proposal?.preTaxReturnPercentageMedium || null,
        mediumTaxDrag: proposal?.taxDragPercentageMedium || null,
        highPreTaxReturn: proposal?.preTaxReturnPercentageHigh || null,
        highTaxDrag: proposal?.taxDragPercentageHigh || null,
      },
      _status: proposal?.status ?? null,
    } as const satisfies GiftDesignerModelScenariosFormShape;
  }, [proposalId, queryFormData, reportError, setProposal]);

  return {
    getDefaultValues,
    ...queryProps,
  };
}

export function calculateTotalWealthForProjection({
  projection,
  shouldShowAfterEstateTax = true,
}: {
  projection: GiftProposalProjection;
  shouldShowAfterEstateTax?: boolean;
}) {
  if (shouldShowAfterEstateTax) {
    return projection.inEstateValueAfterTax.plus(
      projection.outOfEstateTotalValue
    );
  }

  return projection.inEstateValueBeforeTax.plus(
    projection.outOfEstateTotalValue
  );
}
