/* eslint-disable react/display-name */
import {
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro';

import { ExpansionCaret } from '@/components/display/ExpansionCaret/ExpansionCaret';
import { IconProps } from '@/components/icons/ChevronRightIcon';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';

interface ExpansionCaretRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  iconProps?: CellRenderProp<R, V, F, N, Partial<IconProps>>;
}

export const ExpansionCaretRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V = unknown,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  _factoryProps?: ExpansionCaretRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const { id, field, rowNode } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const apiRef = useGridApiContext();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const isExpanded = rowNode.type === 'group' && rowNode.childrenExpanded;
    const filteredDescendantCount =
      filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleClick = (event: React.MouseEvent) => {
      if (rowNode.type !== 'group') return;

      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    if (filteredDescendantCount === 0) return null;

    return (
      <CellContainer
        align={props.colDef.align}
        sx={{ p: 1, pl: rowNode.depth * 2 }}
      >
        <ExpansionCaret isExpanded={isExpanded} onClick={handleClick} />
      </CellContainer>
    );
  };
};
