import { useTheme } from '@mui/material';
import React, { Children, useEffect, useMemo, useRef } from 'react';

import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@/components/icons/ChevronUpIcon';
import { MenuPopper } from '@/components/poppers/MenuPopper/MenuPopper';
import { Slot } from '@/components/utils/slots';

import { Button, ButtonProps } from './Button';
import { ButtonRoot } from './ButtonRoot';
import { ChevronWithOutline } from './HeaderWithPopover';
import { getColorsForVariant, iconSizeByButtonSize } from './styles';
import { ButtonVariant } from './types';

export type ButtonWithPopoverProps = {
  label: React.ReactNode;
  // not all sizes are supported
  size: 'xs' | 'sm' | 'md' | 'lg';
  type?: React.HTMLProps<HTMLButtonElement>['type'];
  popperProps?: Partial<React.ComponentProps<typeof MenuPopper>>;
  isOpen?: boolean;
  popperVariant?: 'menuBelow' | 'menuAbove';
  hideChevron?: boolean;
  buttonSx?: React.ComponentProps<typeof ButtonRoot>['sx'];
  onOpen?: () => void;
  onClose?: () => void;
  onMouseLeave?: (isOpen: boolean) => void;
  onWidthChange?: (width: number) => void;
  slots?: {
    Chevron?: Slot<typeof ChevronWithOutline>;
  };
  highlightWhenOpen?: boolean;
  keepOpen?: boolean;
} & ButtonProps;

function DefaultChevron({
  iconSize,
  isOpen,
}: {
  iconSize: number;
  isOpen: boolean;
}) {
  const sizeProps = { size: iconSize };
  return isOpen ? (
    <ChevronUpIcon {...sizeProps} />
  ) : (
    <ChevronDownIcon {...sizeProps} />
  );
}

// Use dark button background when menu is open
// and the button variant is transparent
function useActiveBackgroundColorStyles(
  variant: ButtonVariant,
  isActive: boolean,
  highlightWhenOpen?: boolean
) {
  const theme = useTheme();
  const primaryColors = getColorsForVariant('primary', theme);

  if (variant === 'transparent' && isActive && highlightWhenOpen) {
    return {
      bgcolor: primaryColors.regular.backgroundColor,
      color: primaryColors.regular.textColor,
    };
  }

  if (variant === 'secondary' && isActive) {
    return {
      borderColor: theme.palette.buttons.light,
    };
  }

  return {};
}

export function ButtonWithPopover(props: ButtonWithPopoverProps) {
  const {
    children,
    label,
    type,
    popperProps,
    isOpen,
    popperVariant,
    hideChevron,
    buttonSx,
    onClick,
    onOpen,
    onClose,
    onMouseLeave,
    onWidthChange,
    slots,
    highlightWhenOpen,
    keepOpen,
    ...sharedButtonProps
  } = props;
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const iconSize = iconSizeByButtonSize[sharedButtonProps.size];

  useEffect(() => {
    if (isOpen === true) {
      setMenuIsOpen(true);
      onOpen?.();
    }
  }, [isOpen, onClose, onOpen, setMenuIsOpen]);

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setMenuIsOpen((isOpen) => !isOpen);
    onClick?.(e);
  }

  const positioning = useMemo(() => {
    if (popperVariant === 'menuBelow') {
      return {
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        transformOrigin: { horizontal: 'right', vertical: 0 },
      } as const;
    }

    return {
      anchorOrigin: { horizontal: 'right', vertical: 0 },
      transformOrigin: { horizontal: 'right', vertical: 'bottom' },
    } as const;
  }, [popperVariant]);

  const activeBackgroundColorStyles = useActiveBackgroundColorStyles(
    sharedButtonProps.variant,
    menuIsOpen,
    highlightWhenOpen
  );

  const ChevronIcon = hideChevron ? undefined : (
    <DefaultChevron iconSize={iconSize} isOpen={menuIsOpen} />
  );

  const buttonSlots = useMemo(() => {
    const EndIcon =
      slots?.Chevron && !hideChevron
        ? {
            EndIcon: {
              component: slots.Chevron.component,
              props: {
                ...slots.Chevron.props,
                iconSize,
                isOpen: menuIsOpen,
              },
            },
          }
        : {};

    return {
      ...EndIcon,
    };
  }, [hideChevron, iconSize, menuIsOpen, slots?.Chevron]);

  const buttonWidth = menuButtonRef.current?.offsetWidth;

  useEffect(() => {
    if (!onWidthChange || !buttonWidth) return;
    onWidthChange(buttonWidth);
  }, [buttonWidth, onWidthChange]);

  const numChildren = Children.count(children);
  const showPopper = numChildren > 0;

  return (
    <>
      {showPopper && (
        <MenuPopper
          {...positioning}
          open={menuIsOpen}
          anchorRef={menuButtonRef}
          onClose={() => {
            setMenuIsOpen(false);
            onClose?.();
          }}
          onClick={() => {
            if (keepOpen) return;
            setMenuIsOpen(false);
          }}
          elevation={8}
          {...popperProps}
        >
          {children}
        </MenuPopper>
      )}
      <Button
        ref={menuButtonRef}
        sx={{
          gap: 1,
          ...activeBackgroundColorStyles,
          ...buttonSx,
        }}
        type={type}
        {...sharedButtonProps}
        onMouseLeave={() => {
          onMouseLeave?.(menuIsOpen);
        }}
        onClick={handleClick}
        endIcon={ChevronIcon ? () => ChevronIcon : undefined}
        slots={buttonSlots}
      >
        {label}
      </Button>
    </>
  );
}
