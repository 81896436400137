// disable the console.* ban for this file because this is a very valid place
// to use console methods
/* eslint-disable ban/ban */
import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { ScopeContext } from '@sentry/types';
import { GraphQLFormattedError } from 'graphql';
import { includes, startCase } from 'lodash';
import { PostHog } from 'posthog-js';

import { TenantUsageBucket } from '@/types/schema';
import { getTenant } from '@/utils/tenantUtils';

import {
  getAppVersion,
  getEnvironment,
  isDevelopmentMode,
} from './environmentUtils';

export function identifyUserWithSession(
  posthogClient: PostHog,
  email: string,
  userId: string,
  tenantUsageBucket: TenantUsageBucket
) {
  const tenantSubdomain = getTenant();
  Sentry.setUser({
    email,
    id: userId,
  });

  posthogClient.identify(userId, {
    email,
    tenant: tenantSubdomain,
    version: getAppVersion(),
  });

  if (!includes(email, '@withluminary.com')) {
    posthogClient.group('company', tenantSubdomain, {
      name: tenantSubdomain,
      usageBucket: tenantUsageBucket,
    });
  }
}

/**
 * @description Error logger for GraphQL network errors
 */
function logGraphQLNetworkError({
  description,
  error,
  tags,
  fingerprint,
}: {
  description: string;
  error?: Error | null;
  tags: ScopeContext['tags'];
  fingerprint?: ScopeContext['fingerprint'];
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { query, ...restTags } = tags;
  const possibleFingerprint = (() => {
    if (fingerprint) {
      return { fingerprint };
    }

    return {};
  })();

  const prettyErrorName = `GraphQLError ${description} ${String(
    tags.operationName
  )}`;
  const captureContext = {
    level: 'error' as const,
    ...possibleFingerprint,
    tags: {
      description,
      ...restTags,
    },
    contexts: {
      GraphQL: {
        description,
        message: error?.message,
        fingerprint,
        ...tags,
      },
    },
  };

  console.error(error, {
    errorName: prettyErrorName,
    ...captureContext,
  });

  Sentry.captureMessage(prettyErrorName, captureContext);
}

function logError({
  description,
  error,
  tags,
  fingerprint,
}: {
  description: string;
  error?: Error | null;
  tags?: ScopeContext['tags'];
  fingerprint?: ScopeContext['fingerprint'];
}) {
  if (tags?.operationName) {
    // this is a GraphQL network error
    logGraphQLNetworkError({
      description,
      error,
      tags,
      fingerprint,
    });
  } else {
    const finalError = error || new Error(description);
    const captureContext = {
      fingerprint,
      tags: {
        description,
        ...tags,
      },
    };

    console.error(finalError, captureContext);
    Sentry.captureException(finalError, captureContext);
  }
}

/**
 * @description Error logger for GraphQL errors returned
 * from the backend
 */
function logGraphQLError({
  err,
  description,
  tags,
}: {
  err: GraphQLFormattedError;
  description: string;
  tags?: ScopeContext['tags'];
}) {
  Sentry.withScope((scope) => {
    const errorPath = (err.path ?? [])
      .map((v: string | number) => (typeof v === 'number' ? '$index' : v))
      .join(' > ');

    const errorTags: ScopeContext['tags'] = {
      errorType: 'GraphQLError',
      description: `GraphQL Error at ${errorPath}`,
      ...tags,
    };

    scope.setTags({ ...errorTags });

    if (errorPath) {
      scope.addBreadcrumb({
        category: 'execution-path',
        message: errorPath,
        level: 'debug',
      });
    }

    const captureContext = {
      level: 'error' as const,
      fingerprint: ['graphql', errorPath],
      contexts: {
        GraphQL: {
          description,
          message: err.message,
          extensions: err.extensions,
          path: errorPath,
        },
      },
    };

    const prettyErrorName = `GraphQLError ${startCase(
      `${err.message} ${description}`
    )}`;

    console.error(err, {
      ...captureContext,
      errorName: prettyErrorName,
      tags: errorTags,
    });

    Sentry.captureMessage(prettyErrorName, captureContext);
  });
}

/**
 *
 * @param description A human-friendly description of the context of the issue
 * @param error The underlying associated error. If no error is generated, you can create your own. This is useful because it make sure there's always an associated stacktrace.
 * @param context Any additional parameters that would be useful for debugging.
 */
export function error(
  description: string,
  error?: Error | null,
  tags?: ScopeContext['tags'],
  fingerprint?: ScopeContext['fingerprint']
) {
  if (error instanceof ApolloError) {
    error.graphQLErrors.forEach((err) => {
      logGraphQLError({
        err,
        description,
        tags,
      });
    });
  } else {
    logError({ description, error: error as Error | null, tags, fingerprint });
  }
}

export function trace(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.trace(message, data);
  }
}

export function debug(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.debug(message, data);
  }
}

export function info(message: unknown, data?: object) {
  if (isDevelopmentMode()) {
    console.info(message, data);
  }

  Sentry.addBreadcrumb({
    level: 'info',
    message: message as string,
    data,
  });
}

export function warn(message?: unknown, data?: object) {
  console.warn(message, data);
  Sentry.addBreadcrumb({
    level: 'warning',
    message: message as string,
    data,
  });
}

export const diagnostics = {
  error,
  warn,
  debug,
  trace,
  info,
  isDevelopmentMode,
  identifyUserWithSession,
  getEnvironment,
  getAppVersion,
};
