import {
  FormControlLabel,
  Radio,
  RadioGroup as MUIRadioGroup,
  RadioProps,
  styled,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { CSSProperties, useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseRadioGroupInputProps,
  FormControlRadioGroupInputProps,
  HelpTextVariant,
  RadioGroupStackingDirection,
} from '../inputTypes';

export interface RadioGroupProps<V> extends FormControlRadioGroupInputProps<V> {
  label?: string;
  hideLabel?: boolean;
  required?: boolean;
  id?: string;
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
}

const StyledIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: `inset 0 0 0 1px ${COLORS.GRAY[300]}`,
  backgroundColor: COLORS.PRIMITIVES.WHITE,

  'input:hover ~ &': {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: COLORS.GRAY[300],
  },
}));

const StyledCheckedIcon = styled(StyledIcon)({
  backgroundColor: COLORS.NAVY[600],
  boxShadow: 'none',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${COLORS.PRIMITIVES.WHITE},${COLORS.PRIMITIVES.WHITE} 24%,transparent 32%)`,
    content: '""',
  },
  '.Mui-disabled &:before': {
    backgroundImage: `radial-gradient(${COLORS.GRAY[400]},${COLORS.GRAY[400]} 24%,transparent 32%)`,
  },
  'input:hover ~ &': {
    backgroundColor: COLORS.NAVY['600'],
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: COLORS.GRAY[300],
  },
});

function StyledRadio(props: RadioProps) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
        py: 0.5,
      }}
      disableRipple
      color="default"
      checkedIcon={<StyledCheckedIcon />}
      icon={<StyledIcon />}
      inputProps={
        {
          'data-testid': props.id,
        } as React.InputHTMLAttributes<HTMLInputElement>
      }
      {...props}
    />
  );
}

function BaseRadioGroupInput({
  row = true,
  stackingDirection = 'row',
  name,
  ...inputProps
}: BaseRadioGroupInputProps<string>) {
  // Strip error since radio group does not support errors
  // eslint-disable-next-line
  const { error, disabled, inputRef, options, labelSx, ...rest } = inputProps;

  const styleForStackingDirection: Record<
    RadioGroupStackingDirection,
    CSSProperties
  > = {
    row: {},
    column: {
      display: 'grid',
      gridTemplateColumns: `repeat(${options.length}, 1fr)`,
    },
  };

  const sxForFormControlLabel: SxProps<Theme> = useMemo(() => {
    const sxForStackingDirection = {
      row: {},
      column: {
        alignItems: 'center',
      },
    };

    const baseLabelSx = Object.assign({}, inputProps.sx ?? {});

    return {
      ...sxForStackingDirection[stackingDirection],
      ...Object.assign(baseLabelSx, labelSx),
    };
  }, [inputProps.sx, labelSx, stackingDirection]);

  return (
    <>
      <MUIRadioGroup
        {...rest}
        ref={inputRef}
        row={row}
        value={inputProps.value}
        onChange={inputProps.onChange}
        style={styleForStackingDirection[stackingDirection]}
      >
        {options.map((option, idx) => {
          const identifier = ['radio', name, option.value, idx]
            .filter((n) => Boolean(n) || n === 0)
            .join('-');
          return (
            <FormControlLabel
              sx={sxForFormControlLabel}
              value={option.value}
              control={<StyledRadio id={identifier} />}
              htmlFor={identifier}
              label={
                // the entirety of this component is already wrapped in a `label` element via the use of FormControlLabel,
                // so this typography element component should be a `span` even though the variant is `label`
                <>
                  <Typography component="span" variant="label">
                    {option.label}
                  </Typography>
                  {option.helpText && (
                    <Typography variant="subtitle2">
                      {option.helpText}
                    </Typography>
                  )}
                </>
              }
              key={identifier}
              disabled={disabled || option.disabled}
            />
          );
        })}
      </MUIRadioGroup>
    </>
  );
}

export function RadioGroup<V>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  required,
  ...inputProps
}: RadioGroupProps<V>) {
  return (
    <FormControl<BaseRadioGroupInputProps<V>>
      id={id}
      hideLabel={hideLabel}
      contextualHelp={contextualHelp}
      component={BaseRadioGroupInput}
      errorMessage={errorMessage}
      inputProps={inputProps}
      label={label}
      required={required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
    />
  );
}
