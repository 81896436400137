import {
  SxProps,
  Theme,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { PropsWithChildren } from 'react';

export type TypographyClampedProps = TypographyProps &
  PropsWithChildren<{
    lines: number;
  }>;

export function TypographyClamped({ lines, ...props }: TypographyClampedProps) {
  let useClamp = false;
  if (
    // if line-clamp and box-orient are supported
    // we can use them to clamp the text

    window !== undefined &&
    CSS?.supports?.('-webkit-line-clamp', '1') &&
    CSS?.supports?.('-webkit-box-orient', 'vertical') &&
    CSS?.supports?.('display', '-webkit-box')
  ) {
    useClamp = true;
  }

  const theme = useTheme();

  let variant = props.variant;
  if (!variant || variant === 'inherit') {
    variant = 'h1';
  }

  // determine the line height of the text variant
  const lineHeight = theme.typography[variant].lineHeight;

  const style: SxProps<Theme> = useClamp
    ? {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: lines,
        whiteSpace: 'pre-wrap',
      }
    : {
        maxHeight: `calc(${lineHeight} * ${lines})`,
        overflow: 'hidden',
      };

  return (
    <Typography {...props} sx={{ ...style, ...props.sx }}>
      {props.children}
    </Typography>
  );
}
