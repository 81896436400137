import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';

export enum BadgeVariants {
  Primary = 'primary',
  Yellow = 'yellow',
  Gray = 'gray',
  Teal = 'teal',
  GrayOutline = 'gray-outline',
  Blue = 'blue',
  NavyLight = 'blue-light',
}

export const badgeStyles: Record<BadgeVariants, SxProps<Theme>> = {
  [BadgeVariants.Primary]: {
    backgroundColor: COLORS.NAVY[500],
    color: COLORS.PRIMITIVES.WHITE,
  },
  [BadgeVariants.Yellow]: {
    backgroundColor: COLORS.FUNCTIONAL.WARNING.DEFAULT,
    color: COLORS.NAVY[900],
  },
  [BadgeVariants.Gray]: {
    backgroundColor: COLORS.GRAY[300],
    color: COLORS.NAVY[900],
  },
  [BadgeVariants.GrayOutline]: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    border: `1px solid ${COLORS.GRAY[300]}`,
    color: COLORS.GRAY[800],
  },
  [BadgeVariants.Teal]: {
    backgroundColor: COLORS.TEAL[600],
    color: COLORS.PRIMITIVES.WHITE,
  },
  [BadgeVariants.Blue]: {
    backgroundColor: COLORS.BLUE[600],
    color: COLORS.PRIMITIVES.WHITE,
  },
  [BadgeVariants.NavyLight]: {
    backgroundColor: COLORS.NAVY[100],
    color: COLORS.NAVY[900],
  },
};

export interface BadgeProps extends Omit<BoxProps, 'display'> {
  variant: keyof typeof badgeStyles;
  display: React.ReactNode;
  textTransform?: CSSProperties['textTransform'];
  // Optional styling for the Typography component of the badge
  sx?: SxProps<Theme>;
  // Optional styling for the Box component that wraps the Typography component
  wrapperSx?: SxProps<Theme>;
}

export function Badge({
  variant,
  display,
  textTransform = 'none',
  sx = {},
  wrapperSx,
  ...wrapperProps
}: BadgeProps) {
  const style = useMemo(() => {
    return badgeStyles[variant];
  }, [variant]);

  const sxProps: SxProps<Theme> = {
    ...style,
    py: 1 / 8,
    px: 1,
    borderRadius: '2px',
    width: 'fit-content',
    textTransform: textTransform,
  };

  Object.assign(sxProps ? sxProps : {}, sx);

  return (
    <Box sx={wrapperSx} {...wrapperProps}>
      <Typography variant="h6" component={'span'} sx={sxProps}>
        {display}
      </Typography>
    </Box>
  );
}
