import { compact } from 'lodash';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { TaxDetail_TaxDetailFragment } from '../../graphql/TaxDetail.generated';
import { getFederalTaxFields } from '../../taxes.utils';

interface FederalTaxMiniTableProps {
  federalTax: TaxDetail_TaxDetailFragment;
}

export function FederalTaxMiniTable({ federalTax }: FederalTaxMiniTableProps) {
  const rate = useFederalEstateTaxFormatted();

  const {
    taxableEstate,
    stateTaxDeduction,
    lifetimeExemptionUsed,
    amountSubjectToTax,
    federalTaxDue,
  } = getFederalTaxFields(federalTax);

  return (
    <MiniTable
      variant="tally"
      rows={compact([
        {
          label: 'Federal estate tax',
          labelProps: { variant: 'h6' },
          value: '',
        },
        {
          label: 'Taxable estate',
          value: formatCurrencyNoDecimals(taxableEstate),
        },
        {
          label: 'State tax deduction',
          value: formatCurrencyNoDecimalsAccounting(
            stateTaxDeduction.abs().negated()
          ),
        },
        {
          label: 'Lifetime exemption used',
          value: formatCurrencyNoDecimalsAccounting(
            lifetimeExemptionUsed.abs().negated()
          ),
        },
        {
          label: 'Amount subject to tax',
          value: formatCurrencyNoDecimals(amountSubjectToTax),
        },
        {
          label: 'Federal tax rate',
          value: `${rate}%` ?? EMPTY_CONTENT_HYPHEN,
        },
        {
          label: 'Federal tax due',
          value: formatCurrencyNoDecimalsAccounting(
            federalTaxDue.abs().negated()
          ),
          valueProps: { color: COLORS.MATH.NEGATIVE },
          labelTooltip: `Assumes taxable estate assets in excess of the remaining exemption are taxed at a ${rate}% federal estate tax rate.`,
        },
      ])}
    />
  );
}
