import { ButtonProps } from '@/components/form/baseInputs/Button';
import {
  IconButton,
  IconButtonProps,
} from '@/components/form/baseInputs/Button/IconButton';
import {
  ChevronRightIcon,
  IconProps,
} from '@/components/icons/ChevronRightIcon';
import { COLORS } from '@/styles/tokens/colors';

interface ExpansionCaretProps {
  /**
   * Whether the caret is currently expanded (pointing down)
   */
  isExpanded?: boolean;
  /**
   * Callback when the caret is clicked
   */
  onClick?: ButtonProps['onClick'];
  /**
   * Additional props to pass to the icon
   */
  iconProps?: Partial<IconProps>;
  /**
   * Additional props to pass to the button
   */
  buttonProps?: Partial<IconButtonProps>;
}

export function ExpansionCaret({
  isExpanded = false,
  onClick,
  iconProps,
  buttonProps,
}: ExpansionCaretProps) {
  const expandedIconStyle = {
    transform: `rotate(90deg)`,
  };

  return (
    <IconButton
      icon={ChevronRightIcon}
      data-testid={`ExpandRow`}
      ariaLabel="Expand cell"
      size="sm"
      variant="transparent"
      onClick={onClick}
      iconProps={{
        sx: {
          color: COLORS.GRAY[500],
          transition: `0.2s`,
          position: 'relative',
          ...(isExpanded ? expandedIconStyle : {}),
        },
        ...iconProps,
      }}
      {...buttonProps}
    />
  );
}
