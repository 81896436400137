import { CSSObject, styled } from '@mui/material';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';
import {
  createContext,
  CSSProperties,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { HEADER_BACKGROUND_COLOR } from '../../constants';
import { InternalDataTableProps } from '../types';

type DataGridInternalComponent = (props: InternalDataTableProps) => JSX.Element;

const rowHoverVariantStyles: Record<
  InternalDataTableProps['rowHoverVariant'],
  CSSObject
> = {
  default: {
    backgroundColor: COLORS.FUNCTIONAL.HOVER,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
};

export const SHOW_ON_ROW_HOVER_CLASSNAME = 'showOnRowHover';

const ThemedDataGridContext = createContext<{
  cellStyle: CSSProperties;
}>({
  cellStyle: {},
});

const ThemedDataGridContextProvider = ({
  children,
  cellStyle,
}: PropsWithChildren<{
  cellStyle: CSSProperties;
}>) => {
  return (
    <ThemedDataGridContext.Provider value={{ cellStyle }}>
      {children}
    </ThemedDataGridContext.Provider>
  );
};

export const useUnguardedThemedDataGridContext = () => {
  return useContext(ThemedDataGridContext);
};

export function ThemedDataGrid(props: InternalDataTableProps) {
  const { cellStyle } = useMemo(() => {
    return {
      cellStyle: props.noPointer
        ? {
            cursor: 'default',
          }
        : {},
    };
  }, [props.noPointer]);

  return (
    <ThemedDataGridContextProvider cellStyle={cellStyle}>
      <ThemedDataGridInner {...props} />
    </ThemedDataGridContextProvider>
  );
}

const ThemedDataGridInner = styled(DataGridPro as DataGridInternalComponent)(
  ({
    rowHoverVariant,
    useTreeStyling,
    useEntityAwareStyling,
    hideHeaderSeparator,
  }) => ({
    // Root styles
    borderColor: 'transparent',
    '& .MuiDataGrid-overlayWrapperInner': {
      display: 'flex',
    },

    // Row styles
    '& .MuiDataGrid-row': {
      backgroundColor: 'white',
      [`.${SHOW_ON_ROW_HOVER_CLASSNAME}`]: {
        display: 'none',
      },
    },
    '& .MuiDataGrid-row:hover': {
      ...rowHoverVariantStyles[rowHoverVariant],
      [`.${SHOW_ON_ROW_HOVER_CLASSNAME}`]: {
        display: 'initial',
      },
    },

    // Column header styles
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: HEADER_BACKGROUND_COLOR,
    },
    '& .MuiDataGrid-columnHeader': {
      // hide extra left/right padding around headers; we rely on our header cell replacement for this
      padding: 0,
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none', // hide weird header cell focus
    },
    '& .MuiDataGrid-columnHeader:not(:last-child)': {
      borderRight: '1px solid white',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'space-between',
      paddingLeft: 1,
      paddingRight: 1,
    },
    ...(hideHeaderSeparator
      ? {
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
        }
      : {}),

    // Cell styles
    // necessary so the top row border extends over the checkbox column
    '& .MuiDataGrid-cellCheckbox': {
      borderTop: `1px solid ${COLORS.GRAY[200]} !important`,
    },

    '& .MuiDataGrid-cell': {
      // all of the padding is managed by the cell type definition itself,
      // rather than doing it globally here.
      padding: 0,
      border: 'none',
      outline: 'none',
    },

    // necessary to remove the focus outline from the cells and column headers
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
      outline: 'none',
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
      {
        outline: 'none',
      },

    // Pagination styles
    '& .MuiTablePagination-toolbar': {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& .MuiTablePagination-spacer': {
      display: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: 'transparent',
    },

    // Tree styling
    ...(useTreeStyling
      ? {
          // these two styles necessary to properly align the collapse arrow
          '& .MuiDataGrid-treeDataGroupingCellToggle': {
            alignContent: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '0 !important',
          },
          '& .MuiDataGrid-treeDataGroupingCell': {
            alignContent: 'center',
            paddingLeft: 2,
          },
          '& .MuiDataGrid-treeDataGroupingCell > span': {
            width: '100%',
          },
          // since the cells don't cover 100% of the table's width, assign the top border at the row level...
          '& .MuiDataGrid-row': {
            borderTop: `1px solid ${COLORS.GRAY[200]}`,
            breakInside: 'avoid',
          },
          '& .shouldShowCursorIcon': {
            cursor: 'pointer !important',
          },
          // ...and then disable the top border on the individual cells
          '& .MuiDataGrid-cell > *, & .MuiDataGrid-treeDataGroupingCell > :not(:first-child) > *':
            {
              borderTop: 'none !important',
              paddingLeft: '8px',
            },
          '& .MuiDataGrid-cellCheckbox': {
            borderTop: 'none !important',
          },
        }
      : {}),

    // Entity styling
    ...(useEntityAwareStyling
      ? {
          // assign the color wedge -- assign to the row's first grid cell
          '& .shouldShowInEstate > .MuiDataGrid-cell:first-of-type': {
            borderLeft: `4px solid ${COLORS.CATEGORIES.IN_ESTATE[300]}`,
          },
          // make the text color match the color wedge
          '& .shouldShowInEstate .MuiTypography-root:first-of-type': {
            color: COLORS.CATEGORIES.IN_ESTATE[600],
          },
          '& .shouldShowFamilyGiving .MuiDataGrid-cell:first-of-type': {
            borderLeft: `4px solid ${COLORS.CATEGORIES.FAMILY_GIVING[300]}`,
          },
          '& .shouldShowFamilyGiving .MuiTypography-root:first-of-type': {
            color: COLORS.CATEGORIES.FAMILY_GIVING[600],
          },
          '& .shouldShowCharitableGiving > .MuiDataGrid-cell:first-of-type': {
            borderLeft: `4px solid ${COLORS.CATEGORIES.CHARITABLE_GIVING[300]}`,
          },
          '& .shouldShowCharitableGiving .MuiTypography-root:first-of-type': {
            color: COLORS.CATEGORIES.CHARITABLE_GIVING[600],
          },
        }
      : {}),
  })
);
