/* eslint-disable react/display-name */
import { Box, Stack } from '@mui/material';
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

import { isString } from '@/utils/stringUtils';

import {
  CellContainer,
  CellContainerProps,
  PrimaryCellTypography,
  PrimaryCellTypographyProps,
  SecondaryCellTypography,
} from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

export interface TwoLineTextValueShape {
  lineOne: NonNullable<ReactNode>;
  lineTwo?: ReactNode;
  // rightContent is rendered on the opposite end of the cell from the text.
  // (the right side in most cases, but the left side if the cell is right-aligned)
  rightContent?: ReactNode;
  // textAccompaniment is rendered directly next to the cell; on the right side in
  // most cases, but on the left side if the cell is right-aligned
  textAccompaniment?: ReactNode;
}

interface TwoLineTextRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  lineOne?: CellRenderProp<R, V, F, N, TwoLineTextValueShape['lineOne']>;
  // lineOneProps will only be applied if lineOne is a string. if lineOne is a
  // ReactNode, put the props on the lineOne element itself.
  lineOneProps?: CellRenderProp<
    R,
    V,
    F,
    N,
    Partial<PrimaryCellTypographyProps>
  >;
  lineTwo?: CellRenderProp<R, V, F, N, TwoLineTextValueShape['lineTwo']>;
  // lineOneProps will only be applied if lineTwo is a string. if lineTwo is a
  // ReactNode, put the props on the lineTwo element itself.
  lineTwoProps?: CellRenderProp<
    R,
    V,
    F,
    N,
    Partial<PrimaryCellTypographyProps>
  >;
  rightContent?: CellRenderProp<
    R,
    V,
    F,
    N,
    TwoLineTextValueShape['rightContent']
  >;
  textAccompaniment?: CellRenderProp<
    R,
    V,
    F,
    N,
    TwoLineTextValueShape['rightContent']
  >;
  cellContainerProps?: CellRenderProp<R, V, F, N, Partial<CellContainerProps>>;
}

export const TwoLineTextRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends TwoLineTextValueShape = TwoLineTextValueShape,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: TwoLineTextRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const lineOne = getCellRenderProp({
      props,
      prop: factoryProps?.lineOne,
      defaultValue: props.value?.lineOne,
    });

    const lineOneProps = getCellRenderProp({
      props,
      prop: factoryProps?.lineOneProps,
      defaultValue: {},
    });

    const lineTwo = getCellRenderProp({
      props,
      prop: factoryProps?.lineTwo,
      defaultValue: props.value?.lineTwo,
    });

    const lineTwoProps = getCellRenderProp({
      props,
      prop: factoryProps?.lineTwoProps,
      defaultValue: {},
    });

    const rightContent = getCellRenderProp({
      props,
      prop: factoryProps?.rightContent,
      defaultValue: null,
    });

    const textAccompaniment = getCellRenderProp({
      props,
      prop: factoryProps?.textAccompaniment,
      defaultValue: null,
    });

    const cellContainerProps = getCellRenderProp({
      props,
      prop: factoryProps?.cellContainerProps,
      defaultValue: {},
    });

    // We need this bit of seemingly redundant "direction=row-reverse" and the
    // backwards conditional-rendering of rightContent, otherwise, the alignment
    // of the textContent will be wonky for right-aligned cells.
    // See reference picture: https://github.com/withluminary/interface/pull/2148/files#diff-17f1223f707b150e916420756c14f4415853fdc8c44c0630ec5830473d5558c4
    const isRightAligned = props.colDef.align === 'right';

    return (
      <CellContainer {...cellContainerProps} align={props.colDef.align}>
        <Stack
          direction={isRightAligned ? 'row-reverse' : 'row'}
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          overflow="hidden"
          spacing={1}
        >
          <Stack
            spacing={1}
            alignItems="center"
            direction={isRightAligned ? 'row-reverse' : 'row'}
            minWidth={0} // Allows flex items to shrink below content size
            flex={1}
          >
            <Stack
              spacing={1}
              overflow="hidden"
              minWidth={0} // Allows flex items to shrink below content size
              // flex={1}
            >
              {isString(lineOne) ? (
                <PrimaryCellTypography
                  {...lineOneProps}
                  noWrap
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {lineOne}
                </PrimaryCellTypography>
              ) : (
                lineOne
              )}
              {lineTwo &&
                (isString(lineTwo) ? (
                  <SecondaryCellTypography
                    // The !important is to override a more specific mui css style
                    sx={{ mt: '0 !important' }}
                    {...lineTwoProps}
                    noWrap
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {lineTwo}
                  </SecondaryCellTypography>
                ) : (
                  lineTwo
                ))}
            </Stack>
            {textAccompaniment}
          </Stack>
          {rightContent && <Box flexShrink={0}>{rightContent}</Box>}
        </Stack>
      </CellContainer>
    );
  };
};
