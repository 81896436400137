import Decimal from 'decimal.js';
import { Path } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import { GiftInEstatePortfolio } from '@/modules/content/tooltipContent/GiftInEstatePortfolio';

import { GiftDesignerBasicInformationFormShape } from './GiftDesignerBasicInformationForm.types';

type GiftDesignerBasicInformationFormPaths =
  Path<GiftDesignerBasicInformationFormShape>;

export const Name = () => {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<GiftDesignerBasicInformationFormShape>();

  if (isLoading) {
    return null;
  }

  return (
    <FormAwareTextInput<GiftDesignerBasicInformationFormShape>
      control={control}
      disabled={isLoading}
      label="Name"
      required={true}
      fieldName={
        `name` as const satisfies GiftDesignerBasicInformationFormPaths
      }
      helpText="Enter the name used in client proposals."
    />
  );
};

export const LengthOfAnalysis = () => {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<GiftDesignerBasicInformationFormShape>();

  return (
    <FormAwareFormattedNumberInput<GiftDesignerBasicInformationFormShape>
      control={control}
      disabled={isLoading}
      label="Length of analysis"
      fieldName={
        `lengthOfAnalysis` as const satisfies GiftDesignerBasicInformationFormPaths
      }
      decimalScale={0}
      required
      isDecimalJSInput
      helpText="Enter length of analysis (# of years)."
      validation={{
        max: (value) => {
          if (Decimal.isDecimal(value)) {
            return value.greaterThan(100)
              ? 'Length of analysis must be ≤ 100 years'
              : undefined;
          }

          return undefined;
        },
        min: (value) => {
          if (Decimal.isDecimal(value)) {
            return value.lessThan(1)
              ? 'Length of analysis must be ≥ 1 year'
              : undefined;
          }

          return undefined;
        },
      }}
    />
  );
};

export const InEstatePortfolioInput = () => {
  const {
    control,
    formState: { isLoading },
  } = useFormContext<GiftDesignerBasicInformationFormShape>();

  return (
    <FormAwareCurrencyInput<GiftDesignerBasicInformationFormShape>
      control={control}
      disabled={isLoading}
      fieldName="inEstatePortfolio"
      isDecimalJSInput
      decimalScale={2}
      required
      label="In-estate portfolio"
      contextualHelp={<GiftInEstatePortfolio />}
    />
  );
};
