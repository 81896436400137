import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import { StateCode } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { TaxDetail_TaxDetailFragment } from '../../graphql/TaxDetail.generated';
import { getStateTaxFields } from '../../taxes.utils';
import { getStateTaxTooltipContent } from './getStateTaxTooltip';

interface StateTaxMiniTableProps {
  stateTax: TaxDetail_TaxDetailFragment;
}

export function StateTaxMiniTable({ stateTax }: StateTaxMiniTableProps) {
  const {
    stateName,
    taxableEstate,
    amountSubjectToTax,
    stateTaxDue,
    taxableAmountOverCliff,
    exemptionUsed,
  } = getStateTaxFields(stateTax);

  const hasCliff =
    taxableAmountOverCliff === true || taxableAmountOverCliff === false;
  const estateExceedsCliff = hasCliff && taxableAmountOverCliff;
  const estateIsBelowCliff = hasCliff && !taxableAmountOverCliff;

  const { showStateExemption, stateExemptionDisplayValue } = useMemo(() => {
    if (!hasCliff) {
      // If no cliff for the state, always show the total exemption used
      return {
        showStateExemption: true,
        stateExemptionDisplayValue: exemptionUsed, // Show exemption used
      };
    }

    if (estateIsBelowCliff && exemptionUsed.greaterThan(0)) {
      // If the estate is below the cliff and has exemption used, show the exemption used
      return {
        showStateExemption: true,
        stateExemptionDisplayValue: exemptionUsed, // Show exemption used
      };
    }

    // This should not happen
    if (estateExceedsCliff && exemptionUsed.greaterThan(0)) {
      const invalidExemptionStatus =
        'Estate value exceeds the cliff, yet we are applying state exemption';
      diagnostics.error(
        invalidExemptionStatus,
        new Error(invalidExemptionStatus),
        {
          stateName,
          taxableEstate: taxableEstate.toString(),
          exemptionUsed: exemptionUsed.toString(),
          estateExceedsCliff,
        }
      );
    }

    // If the estate exceeds the cliff and has no exemption used, don't show anything
    return {
      showStateExemption: false,
      stateExemptionDisplayValue: new Decimal(0), // unused
    };
  }, [
    estateExceedsCliff,
    estateIsBelowCliff,
    exemptionUsed,
    hasCliff,
    stateName,
    taxableEstate,
  ]);

  const stateExemptionTooltip = useMemo(() => {
    if (estateExceedsCliff) {
      // Specific copy for certain states
      if (stateTax.stateCode === StateCode.Il) {
        return `The taxable estate exceeds the IL exemption and the entire taxable estate is taxable`;
      }

      return `The taxable estate exceeds the ${
        stateTax.stateCode ?? 'state'
      } cliff and the entire taxable estate is taxable`;
    }

    return undefined;
  }, [estateExceedsCliff, stateTax.stateCode]);

  return (
    <MiniTable
      variant="tally"
      rows={compact([
        {
          label: `${stateName} estate tax`,
          labelProps: { variant: 'h6' },
          value: '',
        },
        {
          label: 'Taxable estate',
          value: formatCurrencyNoDecimals(taxableEstate),
        },
        showStateExemption
          ? {
              label: `${stateName} exemption used`,
              value: formatCurrencyNoDecimalsAccounting(
                stateExemptionDisplayValue.abs().negated()
              ),
            }
          : null,
        {
          label: 'Amount subject to tax',
          value: formatCurrencyNoDecimals(amountSubjectToTax),
          labelTooltip: stateExemptionTooltip,
        },
        {
          label: 'State tax due',
          value: formatCurrency(stateTaxDue.abs().negated(), {
            currencySign: 'accounting',
            maximumFractionDigits: 0,
          }),
          valueProps: { color: COLORS.ORANGE[600] },
          labelTooltip: stateTax.stateCode
            ? getStateTaxTooltipContent(stateTax.stateCode)
            : undefined,
        },
      ])}
    />
  );
}
