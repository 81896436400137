import { Box, styled } from '@mui/material';
import MuiTooltip, {
  tooltipClasses,
  TooltipProps,
} from '@mui/material/Tooltip';

export const Tooltip = styled(
  ({ className, children, placement = 'top', ...props }: TooltipProps) => (
    <MuiTooltip
      // Allow tooltip to be focusable to make it accessible by keyboard
      tabIndex={0}
      arrow={true}
      classes={{ popper: className }}
      placement={placement}
      {...props}
    >
      <Box component="span">{children}</Box>
    </MuiTooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `4px solid white`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: `${theme.palette.shadows.lg}, ${theme.palette.shadows.lgYInvert}`,
    padding: theme.spacing(1),
    fontSize: 12,
  },
}));

Tooltip;
