import { useCallback, useEffect } from 'react';
import {
  FieldValues,
  // eslint-disable-next-line no-restricted-imports
  useForm as useFormRHF,
  // eslint-disable-next-line no-restricted-imports
  useFormContext as useFormContextRHF,
  UseFormProps,
} from 'react-hook-form';

import { useOnBeforeUnloadPrompt } from '@/hooks/useOnBeforeUnloadPrompt';
import { UseFormReturn } from '@/types/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

/**
 * @description wrapper around useForm that provides common error handling, etc.
 */
export function useForm<
  TFieldValues extends FieldValues = FieldValues,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- mirrors RHF internal types
  TContext = any,
>(
  args?: UseFormProps<TFieldValues, TContext>
): UseFormReturn<TFieldValues, TContext> {
  const { handleSubmit: handleSubmitUnwrapped, ...formMethods } = useFormRHF({
    ...args,
  });

  const { touchedFields, isSubmitting, isSubmitted, isDirty } =
    formMethods.formState;

  const isTouched = JSON.stringify(touchedFields) !== '{}';

  // Should block navigation if:
  // - Form is dirty (because we don't want to lose unsaved changes)
  // - Form is touched (because sometimes we don't set all the form's default values
  //                   on load when programatically setting the form's initial values.
  //                   So to compensate for that we require that the user has touched the
  //                   the form to enable blocking. This seems to work pretty well in practice)
  // - Form is not submitting (because we often navigate on submit)
  // - Form is not submitted  (because once submitted, we don't want to block navigation)
  const shouldBlockNavigationFromForm =
    isDirty && isTouched && !isSubmitted && !isSubmitting;

  // All forms should block navigation by default
  const { shouldBlockNavigation, setShouldBlockNavigation } =
    useOnBeforeUnloadPrompt(shouldBlockNavigationFromForm);

  const handleSubmit = useCallback<typeof handleSubmitUnwrapped>(
    (onSuccess, onError) => {
      return (event, ...rest) => {
        /**
         * NOTE: This is really tricky, and occurs in the scenario where you have two forms nested in the REACT tree,
         * even if separated by a portal they will both be submitted if using form context / form provider.
         * The reason is react events bubble up the react tree regardless of portals even if that's not the case for DOM events.
         *
         * TLDR; this stops multiple nested forms on the same page from submitting each other.
         *
         * https://reactjs.org/docs/portals.html#event-bubbling-through-portals
         * https://github.com/react-hook-form/react-hook-form/issues/2076#issuecomment-744089890
         */
        event?.stopPropagation();
        event?.preventDefault();

        return handleSubmitUnwrapped(onSuccess, onError ?? diagnostics.warn)(
          event,
          ...rest
        );
      };
    },
    [handleSubmitUnwrapped]
  );

  return {
    ...formMethods,
    handleSubmit,
    shouldBlockNavigation,
    setShouldBlockNavigation,
  };
}

/**
 * @description ALWAYS use this vs. calling reset() or performing any operations after "await mutation"
 * when saving a form. Failing to do so can lead to massive memory leaks in RHF.
 * @url https://www.react-hook-form.com/api/useform/reset/#rules
 */
export const useSubmitSuccessHandler = (fn: () => void) => {
  const {
    formState: { isSubmitSuccessful },
  } = useFormContext();

  useEffect(() => {
    isSubmitSuccessful && fn();
  }, [fn, isSubmitSuccessful]);

  return { isSubmitSuccessful };
};

/**
 * @description wrapper around useFormContext that includes the extended context
 */
export function useFormContext<
  TFieldValues extends FieldValues,
>(): UseFormReturn<TFieldValues> {
  const ctx = useFormContextRHF<TFieldValues>() as UseFormReturn<TFieldValues>;

  return ctx;
}
