import Decimal from 'decimal.js';

import { STATE_CODE_TO_NAME } from '@/constants/states';

import { TaxDetail_TaxDetailFragment } from './graphql/TaxDetail.generated';

export function getStateTaxFields(stateTax: TaxDetail_TaxDetailFragment) {
  return {
    stateName:
      STATE_CODE_TO_NAME[stateTax.stateCode ?? ''] || stateTax.stateCode,
    taxableEstate: stateTax.taxableTransfers,
    amountSubjectToTax: stateTax.taxableAmountAfterExemption,
    stateTaxDue: stateTax.tax,
    taxableAmountOverCliff: stateTax.taxableAmountOverCliff,
    exemptionUsed: stateTax.exemptionUsed,
  };
}

export function getFederalTaxFields(federalTax: TaxDetail_TaxDetailFragment) {
  return {
    taxableEstate: federalTax.taxableTransfers ?? new Decimal(0),
    stateTaxDeduction: federalTax.deductions ?? new Decimal(0),
    lifetimeExemptionUsed: federalTax.exemptionUsed ?? new Decimal(0),
    amountSubjectToTax:
      federalTax.taxableAmountAfterExemption ?? new Decimal(0),
    federalTaxDue: federalTax.tax ?? new Decimal(0),
  };
}
