import { Box, styled, Typography, useTheme } from '@mui/material';
import { ComponentProps, MouseEventHandler, ReactNode } from 'react';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';

import { commonTabStyle, getActiveStyle } from './Tabs.utils';
import { TabValidityIndicator } from './TabValidityIndicator';

const StyledButton = styled('button')(() => ({
  ...commonTabStyle,
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  fontFamily: 'inherit',
}));

export interface ButtonTabProps extends ComponentProps<typeof StyledButton> {
  isActive?: boolean;
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  display: ReactNode;
  status?: 'pending' | 'complete';
  badge?: string;
  variant?: 'tab1' | 'tab2';
}

export function ButtonTab({
  isActive,
  display,
  onClick,
  status,
  badge,
  variant = 'tab1',
  style,
  ...btnProps
}: ButtonTabProps) {
  const theme = useTheme();
  return (
    <StyledButton
      type="button"
      style={{ ...style, ...(isActive ? getActiveStyle(variant, theme) : {}) }}
      aria-pressed={isActive}
      onClick={onClick}
      {...btnProps}
    >
      <Box display="flex" alignItems="center" minHeight={20}>
        <Typography whiteSpace="pre-wrap" variant="button">
          {display}
        </Typography>
        {status && (
          <Box ml={1}>
            <TabValidityIndicator isValid={status === 'complete'} />
          </Box>
        )}
        {badge && (
          <Box ml={1}>
            <Badge
              variant={isActive ? BadgeVariants.Primary : BadgeVariants.Gray}
              display={badge}
            />
          </Box>
        )}
      </Box>
    </StyledButton>
  );
}
