import { SxProps, Typography, TypographyProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { COLORS } from '@/styles/tokens/colors';

interface HeaderCellTypographyProps {
  sx?: SxProps<Theme>;
  clampLines?: boolean;
}

export function HeaderCellTypography({
  sx,
  children,
  clampLines = false,
}: React.PropsWithChildren<HeaderCellTypographyProps>) {
  const sharedProps: TypographyProps = {
    component: 'p',
    sx: {
      whiteSpace: 'normal',
      fontWeight: 'bold',
      ...sx,
    },
    variant: 'h6' as const,
    color: COLORS.GRAY[500],
  };

  if (clampLines) {
    return (
      <TypographyClamped lines={3} {...sharedProps}>
        {children}
      </TypographyClamped>
    );
  }

  return <Typography {...sharedProps}>{children}</Typography>;
}
